import React from 'react';
import { Box, Container } from '@chakra-ui/react';
import TransectionsTable from '../components/tables/transectionsTable';

export default function Transections() {
  return (
    <Box sx={{ py: '20px' }}>
      <Container maxW="container.xl">
        <TransectionsTable />
      </Container>
    </Box>
  );
}
