import {
  UsersIcon,
  BlogIcon,
  ContactIcon,
  HomeIcon,
  // SettingsIcon,
  LogoutIcon,
} from '../icons';
import { CiMoneyCheck1 } from 'react-icons/ci';

export const menuLinks = [
  {
    label: 'Overview',
    url: '/dashboard',
    icon: HomeIcon,
  },
  {
    label: 'Users',
    url: '/users',
    icon: UsersIcon,
  },
  {
    label: 'Transactions',
    url: '/transactions',
    icon: CiMoneyCheck1,
  },
  {
    label: 'Contacts',
    url: '/contacts',
    icon: ContactIcon,
  },
  {
    label: 'Blogs',
    url: '/blogs',
    icon: BlogIcon,
  },
];

export const bottomLinks = [
  // {
  //   label: 'Settings',
  //   url: '/settings',
  //   icon: SettingsIcon,
  // },
  {
    label: 'Logout',
    url: '/logout',
    color: 'red.500',
    icon: LogoutIcon,
  },
];
