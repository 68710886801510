import React from 'react';
import { Tag, TagLabel } from '@chakra-ui/react';

interface Props {
  label: string;
  color: 'red' | 'green' | 'yellow' | 'blue' | 'gray';
}

export default function StatusComponent(props: Props) {
  const { label, color } = props;
  return (
    <Tag
      sx={{
        borderRadius: 'md',
        textTransform: 'capitalize',
        fontSize: 'xs',
        fontWeight: '700',
        color: color === 'yellow' ? 'gray.800' : 'white',
        bg: `${color}.500`,
      }}
    >
      <TagLabel>{label}</TagLabel>
    </Tag>
  );
}
