import React from 'react';
import { ChakraProvider } from '@chakra-ui/react';
import theme from './theme';
import { Provider } from 'react-redux';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import Layout from './components/layout';
import Login from './views/login';
import Dashboard from './views/dashboard';
import Users from './views/users';
import Contacts from './views/contactPage';
import Transactions from './views/transactions';
import Blogs from './views/blogs';
import Settings from './views/settings';
import AddBlog from './views/addBlog';
import store from './redux';
import { CookiesProvider } from 'react-cookie';
import AuthProvider from './components/authProvider';

function App() {
  return (
    <ChakraProvider theme={theme}>
      <CookiesProvider>
        <Provider store={store}>
          <BrowserRouter>
            <AuthProvider>
              <Routes>
                <Route path="/" element={<Layout />}>
                  <Route path="/" element={<Navigate to="/dashboard" />} />
                  <Route path="/dashboard" element={<Dashboard />} />
                  <Route path="/users" element={<Users />} />
                  <Route path="/contacts" element={<Contacts />} />
                  <Route path="/transactions" element={<Transactions />} />
                  <Route path="/blogs" element={<Blogs />} />
                  <Route path="/blogs/new" element={<AddBlog />} />
                  <Route path="/blogs/:id" element={<AddBlog />} />
                  <Route path="/settings" element={<Settings />} />
                </Route>
                <Route path="/login" element={<Login />} />
                <Route path="*" element={<Navigate to="/dashboard" />} />
              </Routes>
            </AuthProvider>
          </BrowserRouter>
        </Provider>
      </CookiesProvider>
    </ChakraProvider>
  );
}

export default App;
