import React from 'react';
import {
  Input,
  FormControl,
  FormLabel,
  FormErrorMessage,
  InputProps,
  InputGroup,
  InputLeftElement,
} from '@chakra-ui/react';

interface InputFieldProps extends InputProps {
  error?: string | undefined | boolean;
  label?: string;
  iconLeft?: React.ReactNode;
  maxW?: number | string;
}

export default function InputField(props: InputFieldProps) {
  const { label, error, size, iconLeft, ...rest } = props;
  return (
    <FormControl
      sx={{
        ...(props.maxW && { maxW: props.maxW }),
      }}
      isInvalid={!!error}
    >
      {label && (
        <FormLabel sx={{ fontSize: 'sm', fontWeight: '600' }}>
          {label}
        </FormLabel>
      )}
      <InputGroup
        sx={{
          isolation: 'auto',
        }}
      >
        {iconLeft && (
          <InputLeftElement
            sx={{
              color: 'gray.200',
            }}
            pointerEvents="none"
            children={iconLeft}
          />
        )}
        <Input
          {...rest}
          sx={{
            border: '1px solid',
            backgroundColor: 'gray.800',
            borderColor: 'gray.700',
            borderRadius: 'md',
            outline: 'none',
            fontSize: 'sm',
            fontWeight: '500',
            opacity: '1 !important',
            width: '100%',
            padding: '5px 16px',
            pl: iconLeft ? '40px' : '16px',
            height: size === 'lg' ? '48px' : '40px',
            color: 'white',
            _hover: {
              borderColor: 'gray.600',
            },
            _invalid: {
              borderColor: 'red.400',
            },
            _placeholder: {
              color: 'gray.300',
            },
            '&:focus': {
              borderColor: 'blue.500',
              boxShadow: '0 0 0 3px rgba(66, 153, 225, 0.4)',
            },
          }}
        />
      </InputGroup>
      {error && (
        <FormErrorMessage
          sx={{
            color: 'red.400',
            fontWeight: '600',
            fontSize: '12px',
            mt: 1,
          }}
        >
          {error}
        </FormErrorMessage>
      )}
    </FormControl>
  );
}
