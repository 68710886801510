import React from 'react';
import { Skeleton, Stack } from '@chakra-ui/react';

interface Props {
  columns: number;
  rows: number;
}

export default function TableSkeleton({ columns, rows }: Props) {
  return (
    <Stack
      sx={{
        width: '100%',
      }}
      spacing={4}
    >
      {Array(rows)
        .fill('')
        .map((_, i) => (
          <Stack
            key={i}
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            spacing={6}
            py="7px"
          >
            {Array(columns)
              .fill('')
              .map((_, i) => (
                <Skeleton
                  key={i}
                  height="20px"
                  width="100%"
                  borderRadius="md"
                  startColor="gray.700"
                  endColor="gray.800"
                />
              ))}
          </Stack>
        ))}
    </Stack>
  );
}
