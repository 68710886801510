import React from 'react';
import {
  Textarea,
  FormControl,
  FormLabel,
  FormErrorMessage,
  TextareaProps,
} from '@chakra-ui/react';

interface TextAreaFieldProps extends TextareaProps {
  error?: string | undefined | boolean;
  label?: string;
}

export default function TextAreaField(props: TextAreaFieldProps) {
  const { label, error, ...rest } = props;
  return (
    <FormControl isInvalid={!!error}>
      {label && (
        <FormLabel sx={{ fontSize: 'sm', fontWeight: '600' }}>
          {label}
        </FormLabel>
      )}
      <Textarea
        {...rest}
        sx={{
          border: '1px solid',
          backgroundColor: 'gray.800',
          borderColor: 'gray.700',
          borderRadius: 'md',
          outline: 'none',
          opacity: '1 !important',
          fontSize: 'sm',
          fontWeight: '500',
          width: '100%',
          padding: '10px 16px',
          height: '48px',
          color: 'white',
          _hover: {
            borderColor: 'gray.600',
          },
          _invalid: {
            borderColor: 'red.400',
          },
          _placeholder: {
            color: 'gray.300',
          },
          '&:focus': {
            borderColor: 'blue.500',
            boxShadow: '0 0 0 3px rgba(66, 153, 225, 0.4)',
          },
        }}
      />
      {error && (
        <FormErrorMessage
          sx={{
            color: 'red.400',
            fontWeight: '600',
            fontSize: '12px',
            mt: 1,
          }}
        >
          {error}
        </FormErrorMessage>
      )}
    </FormControl>
  );
}
