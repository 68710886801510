import React from 'react';
import { Box, Container } from '@chakra-ui/react';
import ContactsTable from '../components/tables/contactsTable';

export default function Contacts() {
  return (
    <Box sx={{ py: '20px' }}>
      <Container maxW="container.xl">
        <ContactsTable />
      </Container>
    </Box>
  );
}
