import React from 'react';
import { FormControl, FormLabel, FormErrorMessage } from '@chakra-ui/react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

interface RichEditorProps {
  error?: string | undefined | boolean;
  label?: string;
  value?: string;
  onChange?: (value: string) => void;
}

export default function RichEditor(props: RichEditorProps) {
  const { label, error, value, onChange } = props;
  return (
    <FormControl
      sx={{
        '& .ql-toolbar': {
          borderColor: 'gray.700',
          borderRadius: '6px 6px 0 0',
          bg: 'gray.800',
          color: 'white',
          '& *': {
            color: 'gray.100',
            '&:hover': {
              color: 'white !important',
            },
          },
          '& .ql-stroke': {
            color: 'gray.100',
            stroke: 'currentColor',
          },
          '& button:hover .ql-stroke, & .ql-picker-label:hover .ql-stroke': {
            color: 'white',
            stroke: 'currentColor',
          },
          '& .ql-picker.ql-expanded .ql-picker-label': {
            borderColor: 'gray.700 !important',
            borderRadius: '6px',
          },
          '& .ql-picker-options': {
            borderColor: 'gray.700 !important',
            borderRadius: '6px',
            bg: 'gray.800',
            color: 'white',
            '& .ql-picker-item:hover': {
              color: 'white',
            },
          },
        },
        '& .ql-container': {
          borderColor: 'gray.700',
          borderRadius: '0 0 6px 6px',
          color: 'white',
          minHeight: '300px',
        },
        '& .ql-editor': {
          minHeight: '300px',
          color: 'white',
        },
      }}
      isInvalid={!!error}
    >
      {label && (
        <FormLabel sx={{ fontSize: 'sm', fontWeight: '600' }}>
          {label}
        </FormLabel>
      )}
      <ReactQuill theme="snow" value={value} onChange={onChange} />
      {error && (
        <FormErrorMessage
          sx={{
            color: 'red.400',
            fontWeight: '600',
            fontSize: '12px',
            mt: 1,
          }}
        >
          {error}
        </FormErrorMessage>
      )}
    </FormControl>
  );
}
