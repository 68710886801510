import React, { useEffect, useState } from 'react';
import { Box, CircularProgress } from '@chakra-ui/react';
import { useAuthenticateMutation } from '../api';
import { useNavigate, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { loginReducer } from '../redux/slices/app.slice';

interface Props {
  children: React.ReactNode;
}

export default function AuthProvider({ children }: Props) {
  const { user } = useSelector((state: any) => state.app);
  const [checked, setChecked] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const [authenticate, { data, isError }] = useAuthenticateMutation();

  useEffect(() => {
    if (!user && !isError) {
      authenticate();
    }
  }, [authenticate, isError, user]);

  useEffect(() => {
    if (!user && isError) {
      setChecked(true);
      navigate('/login');
    } else if (user) {
      setChecked(true);
      if (location.pathname === '/login') {
        navigate('/');
      }
    } else if (data) {
      dispatch(loginReducer(data));
      setChecked(true);
      if (location.pathname === '/login') {
        navigate('/');
      }
    }
  }, [data, dispatch, isError, location.pathname, navigate, user]);

  if (!checked) {
    return (
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100vh',
          flexDirection: 'column',
        }}
      >
        <CircularProgress
          size="100px"
          thickness="4px"
          isIndeterminate
          color="blue.500"
        />
        <Box fontSize="sm" fontWeight="700" mt={3}>
          Loading Dashboard...
        </Box>
      </Box>
    );
  }

  return <>{children}</>;
}
