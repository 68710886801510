/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from 'react';
import { GoSearch } from 'react-icons/go';
import InputField from './textField';
import { Button, Box } from '@chakra-ui/react';

interface Props {
  placeholder: string;
  value: string;
  onChange: (e: string) => void;
}

export default function SearchInput(props: Props) {
  const { placeholder, onChange } = props;
  const [search, setSearch] = useState<string>('');

  return (
    <Box
      as="form"
      sx={{
        position: 'relative',
        width: '100%',
        maxWidth: 400,
      }}
      onSubmit={e => {
        e.preventDefault();
        onChange(search);
      }}
    >
      <InputField
        placeholder={placeholder}
        value={search}
        onChange={e => setSearch(e.target.value)}
        type="search"
      />
      <Button
        sx={{
          position: 'absolute',
          right: '4px',
          top: '4px',
          zIndex: 10,
          fontSize: 'lg',
          borderRadius: '4px',
        }}
        type="submit"
        colorScheme="blue"
        variant="gray"
        size="sm"
        ml={2}
      >
        <GoSearch />
      </Button>
    </Box>
  );
}
