/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Heading,
  Text,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  useToast,
} from '@chakra-ui/react';
import { LuPlus } from 'react-icons/lu';
import { PiDotsThreeCircle } from 'react-icons/pi';
import { useGetPostsQuery, useDeletePostMutation } from '../../api';
import { Link } from 'react-router-dom';
import TableSkeleton from '../skeletons/tableSkeleton';
import Moment from 'react-moment';
import 'moment-timezone';
import Pagination from '../ui/pagination';
import DeleteDialog from '../ui/deleteDialog';
import SearchInput from '../ui/searchInput';
import SelectField from '../ui/selectField';
import StatusComponent from '../ui/statusComponent';
import IdComponent from '../ui/idComponent';
import ActionButton from '../ui/actionButton';
import EmptyResult from '../ui/emptyResult';

export default function PostsTable() {
  const toast = useToast();
  const [deleteId, setDeleteId] = useState<string | null>(null);
  const [filter, setFilter] = useState<any>({
    status: '',
    search: '',
    page: 1,
    limit: 15,
  });
  const { data, isLoading, isFetching, refetch } = useGetPostsQuery(filter);
  const [deletePost, { isLoading: isDeleting, isError, isSuccess }] =
    useDeletePostMutation();

  useEffect(() => {
    refetch();
  }, [filter, refetch]);

  useEffect(() => {
    if (isSuccess) {
      toast({
        title: 'Post deleted successfully',
        status: 'success',
        duration: 3000,
        isClosable: true,
      });
      setDeleteId(null);
      refetch();
    }

    if (isError) {
      toast({
        title: 'Something went wrong',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
      setDeleteId(null);
    }
  }, [isSuccess, toast, isError]);
  return (
    <Box>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: {
            base: 'flex-start',
            md: 'center',
          },
          py: 2,
          mb: 4,
          gap: 4,
        }}
      >
        <Heading
          fontWeight={600}
          fontSize={{
            base: 'lg',
            sm: 'xl',
            md: '2xl',
          }}
          lineHeight={{
            base: '40px',
            md: '1.3',
          }}
        >
          Posts
        </Heading>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: {
              base: 'flex-end',
              md: 'center',
            },
            flexDir: {
              base: 'column-reverse',
              md: 'row',
            },
            gap: 4,
            flex: 1,
            maxWidth: '600px',
            ml: {
              base: '-63px',
              md: 'auto',
            },
          }}
        >
          <Box
            sx={{
              display: 'flex',
              gap: 4,
              flex: 1,
              maxWidth: '500px',
            }}
          >
            <SearchInput
              placeholder="Search Posts..."
              value={filter.search}
              onChange={e => setFilter({ ...filter, search: e })}
            />
            <SelectField
              placeholder="Status"
              options={[
                { value: '', label: 'All' },
                { value: 'draft', label: 'Draft' },
                { value: 'published', label: 'Published' },
              ]}
              minW={150}
              fullWidth
              iconLeft={<PiDotsThreeCircle size={20} />}
              value={filter.status}
              onChange={e => setFilter({ ...filter, status: e?.value })}
            />
          </Box>
          <Button
            as={Link}
            to="/blogs/new"
            colorScheme="blue"
            rightIcon={<LuPlus size={20} />}
            minW={120}
          >
            <Text pt="1px">Add Post</Text>
          </Button>
        </Box>
      </Box>
      <Box className="table-wraper">
        <Table>
          <Thead>
            <Tr>
              <Th width="250px">Id</Th>
              <Th width="150px">Thumbnail</Th>
              <Th>Title</Th>
              <Th width={150}>Status</Th>
              <Th width={150}>Created At</Th>
              <Th width="150px">Actions</Th>
            </Tr>
          </Thead>
          <Tbody>
            {!isFetching &&
              data?.docs?.map((post: any) => (
                <Tr key={post.id}>
                  <Td>
                    <IdComponent text={post.id} />
                  </Td>
                  <Td>
                    <Box
                      sx={{
                        width: '40px',
                        height: '40px',
                        borderRadius: 'md',
                        overflow: 'hidden',
                        bg: 'gray.700',
                        img: {
                          width: '100%',
                          height: '100%',
                          objectFit: 'cover',
                        },
                      }}
                    >
                      {post?.thumbnail && (
                        <img src={post.thumbnail} alt={post.title} />
                      )}
                    </Box>
                  </Td>
                  <Td
                    sx={{
                      maxWidth: '250px',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      whiteSpace: 'nowrap',
                    }}
                  >
                    {post.title}
                  </Td>
                  <Td>
                    <StatusComponent
                      label={post.status}
                      color={post.status === 'published' ? 'green' : 'yellow'}
                    />
                  </Td>
                  <Td>
                    <Moment format="YYYY/MM/DD">{post.createdAt}</Moment>
                  </Td>
                  <Td>
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: 2,
                      }}
                    >
                      <ActionButton type="edit" link={`/blogs/${post.id}`} />
                      <ActionButton
                        type="delete"
                        onClick={() => setDeleteId(post.id)}
                      />
                    </Box>
                  </Td>
                </Tr>
              ))}
            {(isLoading || isFetching) && (
              <Tr>
                <Td colSpan={6}>
                  <TableSkeleton rows={15} columns={6} />
                </Td>
              </Tr>
            )}
            {!isLoading && !data?.docs?.length && (
              <Tr>
                <Td colSpan={6}>
                  <EmptyResult />
                </Td>
              </Tr>
            )}
          </Tbody>
        </Table>
      </Box>
      <Pagination
        totalPages={data?.total ? Math.ceil(data.total / data.limit) : 1}
        onPageChange={(page: any) => setFilter({ ...filter, page: page })}
        currentPage={filter.page}
      />
      <DeleteDialog
        isOpen={!!deleteId}
        onClose={() => setDeleteId(null)}
        name="Post"
        isLoading={isDeleting}
        onDelete={() => {
          deletePost(deleteId || '');
        }}
      />
    </Box>
  );
}
