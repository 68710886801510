import React from 'react';
import { RiDeleteBin5Fill } from 'react-icons/ri';
import { MdModeEditOutline } from 'react-icons/md';
import { MdRemoveRedEye } from 'react-icons/md';

import { Link } from 'react-router-dom';
import { IconButton } from '@chakra-ui/react';

interface Props {
  type: 'edit' | 'delete' | 'view';
  onClick?: () => void;
  link?: string;
}

export default function ActionButton(props: Props) {
  const { type, onClick, link } = props;
  return (
    <>
      {type === 'edit' && (
        <Link to={link ? link : '#'}>
          <IconButton
            size="xs"
            variant="outline"
            aria-label="Edit"
            sx={{
              borderRadius: 'md',
              minWidth: '28px',
              minHeight: '28px',
              fontSize: 'md',
              borderColor: 'gray.400',
            }}
            icon={<MdModeEditOutline />}
          />
        </Link>
      )}
      {type === 'delete' && (
        <IconButton
          size="xs"
          sx={{
            borderRadius: 'md',
            minWidth: '28px',
            minHeight: '28px',
            fontSize: 'md',
            borderColor: 'gray.400',
            _hover: {
              color: 'white',
              bg: 'red.500',
            },
          }}
          variant="outline"
          aria-label="Delete"
          icon={<RiDeleteBin5Fill />}
          onClick={onClick}
        />
      )}
      {type === 'view' && (
        <IconButton
          size="xs"
          variant="outline"
          aria-label="View"
          onClick={onClick}
          sx={{
            borderRadius: 'md',
            minWidth: '28px',
            minHeight: '28px',
            fontSize: 'md',
            borderColor: 'gray.400',
          }}
          icon={<MdRemoveRedEye />}
        />
      )}
    </>
  );
}
