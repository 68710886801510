/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import {
  Box,
  Heading,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
} from '@chakra-ui/react';
import { useGetTransactionsQuery } from '../../api';
import TableSkeleton from '../skeletons/tableSkeleton';
import Moment from 'react-moment';
import 'moment-timezone';
import Pagination from '../ui/pagination';
import StatusComponent from '../ui/statusComponent';
import IdComponent from '../ui/idComponent';
import EmptyResult from '../ui/emptyResult';
import { IoMdArrowUp, IoMdArrowDown } from 'react-icons/io';

const SortableTh = ({ width, label, sortKey, filter, setFilter }: any) => {
  const isSorted = filter[sortKey] !== undefined;
  const isDesc = filter[sortKey] === 'desc';
  const Icon = isDesc ? IoMdArrowDown : IoMdArrowUp;
  const handleClick = () => {
    if (!isSorted) {
      setFilter({
        page: 1,
        limit: 15,
        [sortKey]: 'desc',
      });
    } else if (isDesc) {
      setFilter({
        page: 1,
        limit: 15,
        [sortKey]: 'asc',
      });
    } else {
      setFilter({
        page: 1,
        limit: 15,
      });
    }
  };
  return (
    <Th width={width || 100} onClick={handleClick} sx={{ cursor: 'pointer' }}>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        {label} {isSorted && <Icon />}
      </Box>
    </Th>
  );
};

export default function ContactsTable() {
  const [filter, setFilter] = useState<any>({
    page: 1,
    limit: 15,
  });
  const { data, isLoading, isFetching, refetch } =
    useGetTransactionsQuery(filter);

  useEffect(() => {
    refetch();
  }, [filter, refetch]);
  return (
    <Box>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          py: 2,
          mb: 4,
          gap: 4,
        }}
      >
        <Heading
          fontWeight={600}
          fontSize={{
            base: 'lg',
            sm: 'xl',
            md: '2xl',
          }}
        >
          Transactions
        </Heading>
      </Box>
      <Box className="table-wraper">
        <Table>
          <Thead>
            <Tr>
              <Th minW={150} width={250}>
                Transaction Id
              </Th>
              <Th minW={100} width={250}>
                User Id
              </Th>
              <SortableTh
                width={100}
                label="Credits"
                sortKey="creditsSort"
                filter={filter}
                setFilter={setFilter}
              />
              <SortableTh
                width={150}
                label="Status"
                sortKey="statusSort"
                filter={filter}
                setFilter={setFilter}
              />
              <SortableTh
                width={150}
                label="Created At"
                sortKey="createdAtSort"
                filter={filter}
                setFilter={setFilter}
              />
            </Tr>
          </Thead>
          <Tbody>
            {!isFetching &&
              data?.docs?.map((post: any) => (
                <Tr key={post.id}>
                  <Td>
                    <IdComponent text={post.id} />
                  </Td>
                  <Td>
                    <IdComponent text={post.uid} />
                  </Td>
                  <Td>{post.credits}</Td>
                  <Td>
                    <StatusComponent
                      label={post.status}
                      color={post.status === 'completed' ? 'green' : 'yellow'}
                    />
                  </Td>
                  <Td>
                    <Moment format="YYYY/MM/DD hh:mm:ss">
                      {post.created_at}
                    </Moment>
                  </Td>
                </Tr>
              ))}
            {(isLoading || isFetching) && (
              <Tr>
                <Td colSpan={6}>
                  <TableSkeleton rows={15} columns={6} />
                </Td>
              </Tr>
            )}
            {!isLoading && !data?.docs?.length && (
              <Tr>
                <Td colSpan={6}>
                  <EmptyResult />
                </Td>
              </Tr>
            )}
          </Tbody>
        </Table>
      </Box>
      <Pagination
        totalPages={data?.total ? Math.ceil(data.total / data.limit) : 1}
        onPageChange={(page: any) => setFilter({ ...filter, page: page })}
        currentPage={filter.page}
      />
    </Box>
  );
}
