import React from 'react';
import { Outlet } from 'react-router-dom';
import { Box } from '@chakra-ui/react';
import Sidebar from './sidebar';
import Header from './header';

export default function Layout() {
  return (
    <Box
      sx={{
        minHeight: '100vh',
        pl: '279px',
        pr: '5px',
        '@media (max-width: 1070px)': {
          pl: '0',
        },
      }}
    >
      <Sidebar />
      <Header />
      <Outlet />
    </Box>
  );
}
