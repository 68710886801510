/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import {
  Box,
  Heading,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
} from '@chakra-ui/react';
import { useGetTransactionsQuery } from '../../api';
import TableSkeleton from '../skeletons/tableSkeleton';
import Moment from 'react-moment';
import 'moment-timezone';
import StatusComponent from '../ui/statusComponent';
import IdComponent from '../ui/idComponent';
import EmptyResult from '../ui/emptyResult';

export default function DashboardTables() {
  const { data, isLoading, isFetching } = useGetTransactionsQuery({
    limit: 5,
  });
  return (
    <Box mt={10}>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          py: 2,
          mb: 4,
          gap: 4,
        }}
      >
        <Heading fontWeight={600} fontSize="xl">
          Latest Transactions
        </Heading>
      </Box>
      <Box
        className="table-wraper"
        sx={{
          height: '320px !important',
          mt: '10px',
        }}
      >
        <Table>
          <Thead>
            <Tr>
              <Th width="200px">Transection Id</Th>
              <Th width="200px">User Id</Th>
              <Th width={150}>Credits</Th>
              <Th width={150}>Status</Th>
              <Th minW={170} width={150}>
                Created At
              </Th>
            </Tr>
          </Thead>
          <Tbody>
            {!isFetching &&
              data?.docs?.map((post: any) => (
                <Tr key={post.id}>
                  <Td>
                    <IdComponent text={post.id} />
                  </Td>
                  <Td>
                    <IdComponent text={post.uid} />
                  </Td>
                  <Td>{post.credits}</Td>
                  <Td>
                    <StatusComponent
                      label={post.status}
                      color={post.status === 'completed' ? 'green' : 'yellow'}
                    />
                  </Td>
                  <Td>
                    <Moment format="YYYY/MM/DD hh:mm:ss">
                      {post.created_at}
                    </Moment>
                  </Td>
                </Tr>
              ))}
            {(isLoading || isFetching) && (
              <Tr>
                <Td colSpan={6}>
                  <TableSkeleton rows={15} columns={6} />
                </Td>
              </Tr>
            )}
            {!isLoading && !data?.docs?.length && (
              <Tr>
                <Td colSpan={6}>
                  <EmptyResult />
                </Td>
              </Tr>
            )}
          </Tbody>
        </Table>
      </Box>
    </Box>
  );
}
