/* eslint-disable react-hooks/exhaustive-deps */
import {
  Box,
  Button,
  Stack,
  Heading,
  Divider,
  useToast,
  IconButton,
} from '@chakra-ui/react';
import React, { useEffect } from 'react';
import InputField from '../../components/ui/textField';
import DeleteDialog from '../ui/deleteDialog';
import SelectField from '../ui/selectField';
import RichEditor from '../ui/richEditor';
import { IoArrowBackOutline } from 'react-icons/io5';
import {
  useCreatePostMutation,
  useLazyGetPostQuery,
  useUpdatePostMutation,
  useDeletePostMutation,
} from '../../api';
import TextAreaField from '../ui/textAreaField';
import ImagePicker from '../ui/imagePicker';
import { useFormik } from 'formik';
import { useNavigate, useParams } from 'react-router-dom';
import * as Yup from 'yup';
import { Link } from 'react-router-dom';

const generateSlug = (text: String) => {
  const slug = text
    .toString()
    .toLowerCase()
    .replace(/\s+/g, '-') // Replace spaces with -
    .replace(/--+/g, '-') // Replace multiple - with single -
    .replace(/^-+/, '') // Trim - from start of text
    .replace(/-+$/, '');

  return slug;
};

const initialValues = {
  title: '',
  content: '',
  slug: '',
  description: '',
  status: 'published',
  thumbnail: null,
  prevThumbnail: null,
};

const validationSchema = Yup.object({
  title: Yup.string().required('Title is required'),
  content: Yup.string(),
  slug: Yup.string().required('Slug is required'),
  description: Yup.string(),
  status: Yup.string().required('Status is required'),
  thumbnail: Yup.mixed().nullable(),
});

export default function PostsForm() {
  const [deleteId, setDeleteId] = React.useState<string | null>(null);
  const navigate = useNavigate();
  const { id } = useParams();
  const toast = useToast();
  const [createPost, response] = useCreatePostMutation();
  const [updatePost, updateResponse] = useUpdatePostMutation();
  const [deletePost, deleteResponse] = useDeletePostMutation();
  const [getPost, getPostResponse] = useLazyGetPostQuery();
  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async values => {
      const formData = new FormData();
      formData.append('title', values.title);
      formData.append('content', values.content);
      formData.append('slug', values.slug);
      formData.append('description', values.description);
      formData.append('status', values.status);
      if (values.thumbnail) {
        formData.append('thumbnail', values.thumbnail);
      }
      if (!id) {
        createPost(formData)
          .then((data: any) => {
            if (data.error) {
              throw new Error(
                data?.error?.data?.message || 'Something went wrong',
              );
            }
            toast({
              title: 'Post Created',
              description: 'Post has been successfully created',
              status: 'success',
              duration: 5000,
              isClosable: true,
              position: 'top',
            });
            navigate(`/blogs/${data.data.id}`);
          })
          .catch(err => {
            toast({
              title: 'Post Creation Failed',
              description: err.message,
              status: 'error',
              duration: 5000,
              isClosable: true,
              position: 'top',
            });
          });
      } else {
        updatePost({ id, body: formData })
          .then((data: any) => {
            if (data.error) {
              throw new Error(
                data?.error?.data?.message || 'Something went wrong',
              );
            }
            toast({
              title: 'Post Updated',
              description: 'Post has been successfully updated',
              status: 'success',
              duration: 5000,
              isClosable: true,
              position: 'top',
            });
            formik.setFieldValue('thumbnail', null);
            formik.setFieldValue('prevThumbnail', data.data.thumbnail);
          })
          .catch(err => {
            toast({
              title: 'Post Update Failed',
              description: err.message,
              status: 'error',
              duration: 5000,
              isClosable: true,
              position: 'top',
            });
          });
      }
    },
  });

  useEffect(() => {
    if (id) {
      getPost(id)
        .then((data: any) => {
          if (data.error) {
            throw new Error(
              data?.error?.data?.message || 'Something went wrong',
            );
          }
          formik.setValues({
            title: data.data.title,
            content: data.data.content,
            slug: data.data.slug,
            description: data.data.description,
            status: data.data.status,
            thumbnail: null,
            prevThumbnail: data.data.thumbnail,
          });
        })
        .catch(err => {
          toast({
            title: 'Post Fetch Failed',
            description: err.message,
            status: 'error',
            duration: 5000,
            isClosable: true,
            position: 'top',
          });
          navigate('/blogs');
        });
    }
  }, [id]);

  useEffect(() => {
    if (deleteResponse.isSuccess) {
      toast({
        title: 'Post deleted successfully',
        status: 'success',
        duration: 3000,
        isClosable: true,
      });
      setDeleteId(null);
      navigate('/blogs');
    }

    if (deleteResponse.isError) {
      toast({
        title: 'Something went wrong',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
      setDeleteId(null);
    }
  }, [deleteResponse.isSuccess, toast, deleteResponse.isError]);
  return (
    <form onSubmit={formik.handleSubmit}>
      <Box
        sx={{
          display: 'flex',
          minHeight: '100vh',
        }}
      >
        <Stack
          spacing={6}
          sx={{
            width: 'calc(100% - 300px)',
            borderRight: '1px solid',
            borderColor: 'gray.700',
            paddingRight: '20px',
            py: '20px',
          }}
        >
          <Heading fontSize="xl" color="gray.200">
            <IconButton
              variant="ghost"
              size="sm"
              fontSize="25px"
              as={Link}
              to="/blogs"
              sx={{
                svg: {
                  position: 'relative',
                  left: '-6px',
                  top: '-2px',
                },
              }}
              icon={<IoArrowBackOutline />}
              aria-label="Back"
            />{' '}
            {id ? 'Edit Post' : 'Add New Post'}
          </Heading>
          <InputField
            label="Title"
            placeholder="Post Title..."
            size="lg"
            name="title"
            value={formik.values.title}
            onChange={e => {
              formik.setFieldValue('title', e.target.value);
              formik.setFieldValue('slug', generateSlug(e.target.value));
            }}
            error={formik.touched.title && formik.errors.title}
          />
          <RichEditor
            label="Content"
            value={formik.values.content}
            onChange={e => formik.setFieldValue('content', e)}
            error={formik.touched.content && formik.errors.content}
          />
          <Box py={3}>
            <Divider borderColor="gray.700" />
          </Box>
          <Heading fontSize="md" color="gray.50">
            Meta Data
          </Heading>
          <InputField
            label="Slug"
            placeholder="Post Slug..."
            size="lg"
            name="slug"
            value={formik.values.slug}
            onChange={formik.handleChange}
            error={formik.touched.slug && formik.errors.slug}
          />
          <TextAreaField
            label="Meta Description"
            placeholder="Meta Description..."
            size="lg"
            name="description"
            value={formik.values.description}
            onChange={formik.handleChange}
            error={formik.touched.description && formik.errors.description}
          />
        </Stack>
        <Stack
          sx={{
            width: '300px',
            py: '30px',
            pl: '20px',
            position: 'sticky',
            top: '0',
          }}
          spacing={6}
        >
          <Stack
            sx={{
              pb: '3',
              position: 'sticky',
              top: '20px',
            }}
            spacing={6}
          >
            <Stack spacing={4} direction="row">
              {id ? (
                <>
                  <Button
                    variant="delete"
                    flex={1}
                    isDisabled={
                      updateResponse.isLoading ||
                      getPostResponse.isLoading ||
                      deleteResponse.isLoading
                    }
                    onClick={() => setDeleteId(id)}
                  >
                    Delete
                  </Button>
                  <Button
                    type="submit"
                    flex={1}
                    isDisabled={
                      updateResponse.isLoading ||
                      getPostResponse.isLoading ||
                      deleteResponse.isLoading
                    }
                    isLoading={
                      updateResponse.isLoading || getPostResponse.isLoading
                    }
                    loadingText={
                      updateResponse.isLoading ? 'Updating...' : 'Please wait'
                    }
                  >
                    Save Changes
                  </Button>
                </>
              ) : (
                <>
                  <Button
                    variant="delete"
                    flex={1}
                    as={Link}
                    to="/blogs"
                    isDisabled={response.isLoading || getPostResponse.isLoading}
                  >
                    Discard
                  </Button>
                  <Button
                    type="submit"
                    flex={1}
                    isDisabled={response.isLoading || getPostResponse.isLoading}
                    isLoading={response.isLoading || getPostResponse.isLoading}
                    loadingText={
                      response.isLoading ? 'Creating...' : 'Please wait'
                    }
                  >
                    Create
                  </Button>
                </>
              )}
            </Stack>
            <Divider borderColor="gray.700" />
          </Stack>
          <SelectField
            label="Status"
            value={formik.values.status}
            onChange={(e: any) => formik.setFieldValue('status', e?.value)}
            error={formik.touched.status && formik.errors.status}
            options={[
              {
                label: 'Published',
                value: 'published',
              },
              {
                label: 'Draft',
                value: 'draft',
              },
            ]}
          />
          <ImagePicker
            label="Featured Image"
            onChange={(e: any) => formik.setFieldValue('thumbnail', e)}
            error={formik.touched.thumbnail && formik.errors.thumbnail}
            value={formik.values.prevThumbnail}
          />
        </Stack>
      </Box>
      <DeleteDialog
        isOpen={!!deleteId}
        onClose={() => setDeleteId(null)}
        name="Post"
        isLoading={deleteResponse.isLoading}
        onDelete={() => {
          deletePost(deleteId || '');
        }}
      />
    </form>
  );
}
