import React from 'react';
import { Box, Container } from '@chakra-ui/react';
import PostsTable from '../components/tables/postsTable';

export default function Blogs() {
  return (
    <Box sx={{ py: '20px' }}>
      <Container maxW="container.xl">
        <PostsTable />
      </Container>
    </Box>
  );
}
