/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  Skeleton,
  ModalBody,
  ModalCloseButton,
  IconButton,
  CircularProgress,
} from '@chakra-ui/react';
import { useLazyGetContactQuery, useUpdateContactMutation } from '../../api';
import TextAreaField from '../ui/textAreaField';
import InputField from '../ui/textField';
import { FaCheck } from 'react-icons/fa6';

const ContactSkeleton = () => (
  <>
    <Skeleton
      height="20px"
      startColor="gray.600"
      endColor="gray.800"
      borderRadius="lg"
      width={50}
      mb={2}
    />
    <Skeleton
      startColor="gray.600"
      endColor="gray.800"
      height="40px"
      mb={4}
      borderRadius="lg"
    />
    <Skeleton
      startColor="gray.600"
      endColor="gray.800"
      height="20px"
      width={50}
      mb={2}
      borderRadius="lg"
    />
    <Skeleton
      startColor="gray.600"
      endColor="gray.800"
      height="40px"
      mb={4}
      borderRadius="lg"
    />
    <Skeleton
      startColor="gray.600"
      endColor="gray.800"
      height="20px"
      width={50}
      mb={2}
      borderRadius="lg"
    />
    <Skeleton
      startColor="gray.600"
      endColor="gray.800"
      height="40px"
      mb={4}
      borderRadius="lg"
    />
    <Skeleton
      startColor="gray.600"
      endColor="gray.800"
      height="20px"
      width={50}
      mb={2}
      borderRadius="lg"
    />
    <Skeleton
      startColor="gray.600"
      endColor="gray.800"
      height="100px"
      mb={4}
      borderRadius="lg"
    />
  </>
);

interface Props {
  id: string | null;
  onClose: () => void;
  onRefresh: () => void;
}

export default function ViewContactModal({ id, onClose, onRefresh }: Props) {
  const [getContact, { data, isFetching }] = useLazyGetContactQuery();
  const [updateContact, updateResponse] = useUpdateContactMutation();

  useEffect(() => {
    if (id) {
      getContact(id);
    }
  }, [id, getContact]);

  useEffect(() => {
    if (updateResponse.isSuccess) {
      onRefresh();
      onClose();
      updateResponse.reset();
    }
  }, [updateResponse.isSuccess]);
  return (
    <Modal isOpen={!!id} isCentered scrollBehavior="inside" onClose={onClose}>
      <ModalOverlay />
      <ModalContent
        sx={{
          bg: 'gray.900',
          border: '1px solid',
          borderColor: 'gray.700',
          borderRadius: '2xl',
          p: '15px 0 0',
        }}
      >
        {data?.status === 'pending' && (
          <IconButton
            aria-label="Mark as read"
            icon={
              updateResponse.isLoading ? (
                <CircularProgress isIndeterminate size="20px" />
              ) : (
                <FaCheck />
              )
            }
            colorScheme="green"
            variant="ghost"
            isDisabled={updateResponse.isLoading}
            size="sm"
            isLoading={updateResponse.isLoading}
            onClick={() => {
              if (id) {
                updateContact({
                  id,
                  body: {
                    status: 'completed',
                  },
                });
              }
            }}
            sx={{
              position: 'absolute',
              right: '45px',
              top: '8px',
              borderRadius: 'full',
              p: '5px',
              fontSize: '18px',
              zIndex: 10,
              _hover: {
                bg: 'gray.700',
              },
            }}
          />
        )}
        <ModalCloseButton
          sx={{
            zIndex: 10,
            borderRadius: '50%',
            _hover: {
              bg: 'gray.700',
            },
          }}
        />
        <ModalBody>
          {isFetching ? (
            <ContactSkeleton />
          ) : (
            <>
              <InputField
                label="Name"
                value={data?.name || ''}
                isDisabled={true}
                mb={4}
              />
              <InputField
                label="Email"
                value={data?.email || ''}
                isDisabled={true}
                mb={4}
              />
              <InputField
                label="subject"
                value={data?.subject || ''}
                isDisabled={true}
                mb={4}
              />
              <TextAreaField
                label="Message"
                value={data?.message || ''}
                isDisabled={true}
                mb={4}
              />
            </>
          )}
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}
