import { extendTheme, withDefaultColorScheme } from '@chakra-ui/react';

const theme = extendTheme(
  {
    config: {
      initialColorMode: 'dark',
      useSystemColorMode: false,
    },
    direction: 'ltr',
    fonts: {
      heading: "'Open Sans', sans-serif",
      body: "'Open Sans', sans-serif",
    },
    styles: {
      global: () => ({
        'html, body': {
          scrollBehavior: 'smooth',
        },
        body: {
          backgroundColor: 'gray.900',
          color: 'white',
          transision: 'none !important',
          overflowX: 'hidden',
        },
        'html body .chakra-alert': {
          background: 'white',
          borderRadius: '13px',
          border: 'none',
          color: 'black',
          fontSize: '13px',
          padding: '10px 30px 10px 10px',
          'button[aria-label="Close"]': {
            top: '10px',
            right: '8px',
          },
          '.chakra-alert__icon[data-status="info"]': {
            color: 'blue.500',
          },
          '.chakra-alert__icon[data-status="warning"]': {
            color: 'yellow.500',
          },
          '.chakra-alert__icon[data-status="success"]': {
            color: 'green.500',
          },
          '.chakra-alert__icon[data-status="error"]': {
            color: 'red.500',
          },
        },
        '::-webkit-scrollbar': {
          width: '6px',
          height: '6px',
          borderRadius: '24px',
        },
        '::-webkit-scrollbar-track': {
          width: '6px',
          height: '6px',
          borderRadius: '24px',
        },
        '::-webkit-scrollbar-thumb': {
          background: 'gray.600',
          borderRadius: '24px',
        },
        '::-webkit-scrollbar-thumb:hover': {
          background: 'gray.500',
        },
        'body .table-wraper': {
          bg: 'gray.800 !important',
          borderRadius: 'lg !important',
          overflowX: 'auto',
        },
        'body td': {
          borderColor: 'transparent !important',
          p: '13px 16px !important',
          fontSize: 'sm !important',
          color: 'gray.100 !important',
          maxW: '250px !important',
        },
        'body th': {
          borderColor: 'gray.700 !important',
          p: '17px 16px 14px !important',
          fontSize: 'xs !important',
          textTransform: 'capitalize !important',
          color: 'white !important',
        },
      }),
    },
    sizes: {
      container: {
        xl: '1200px',
      },
    },
    colors: {
      blue: {
        900: '#000000',
        800: '#131621',
        700: '#272d41',
        600: '#0047b0',
        500: '#0066fb',
        400: '#1b73f5',
        300: '#3385fc',
        200: '#4d94fc',
        100: '#66a3fd',
        50: '#99c2fd',
      },
      gray: {
        900: '#07080d',
        800: '#0f131e',
        700: '#20242e',
        600: '#30374c',
        500: '#3a3f54',
        400: '#4a4f5c',
        300: '#818492',
        200: '#b8b9bc',
        100: '#d0d0d2',
        50: '#e7e8e9',
      },
    },
    shadows: {
      md: '0 5px 20px 0 #00000010',
      lg: '0 5px 42px 0 #00000014',
    },
    components: {
      Button: {
        variants: {
          solid: {
            bg: 'blue.500',
            color: 'white',
            fontWeight: 'bold',
            fontSize: 'sm',
            borderRadius: 'lg',
            boxShadow:
              'rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 0px, #99c2fd 0px 1px 2px 0px inset, rgba(0, 0, 0, 0.16) 0px 2px 3px 0px, rgba(0, 0, 0, 0.4) 0px -1px 2px 0px inset',
            _hover: {
              bg: 'blue.400',
              color: 'white',
            },
            _active: {
              bg: 'blue.600',
              color: 'white',
            },
          },
          delete: {
            bg: 'red.600',
            color: 'white',
            fontWeight: 'bold',
            fontSize: 'sm',
            borderRadius: 'lg',
            boxShadow:
              'rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 0px, #fd9999 0px 1px 2px 0px inset, rgba(0, 0, 0, 0.16) 0px 2px 3px 0px, rgba(0, 0, 0, 0.4) 0px -1px 2px 0px inset',
            _hover: {
              bg: 'red.500',
              color: 'white',
            },
            _active: {
              bg: 'red.700',
              color: 'white',
            },
          },
          outline: {
            color: 'gray.100',
            borderColor: 'gray.100',
            fontWeight: 'bold',
            fontSize: 'sm',
            borderRadius: 'lg',
            _hover: {
              bg: 'blue.400',
              color: 'white',
            },
            _active: {
              bg: 'blue.600',
              color: 'white',
            },
          },
          ghost: {
            bg: 'transparent',
            color: 'gray.100',
            fontWeight: 'bold',
            fontSize: 'sm',
            borderRadius: 'lg',
            _hover: {
              bg: 'transparent',
              color: 'white',
            },
            _active: {
              bg: 'transparent',
              color: 'white',
            },
          },
          gray: {
            bg: 'gray.700',
            color: 'white',
            fontWeight: 'bold',
            fontSize: 'sm',
            borderRadius: 'lg',
            shadow:
              'rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgb(98, 110, 156) 0px 1px 2px 0px inset, rgba(0, 0, 0, 0.16) 0px 2px 3px 0px, rgba(0, 0, 0, 0.4) 0px -1px 2px 0px inset',
            _hover: {
              bg: 'gray.600',
              color: 'white',
            },
            _active: {
              bg: 'gray.800',
              color: 'white',
            },
          },
        },
      },
    },
  },
  withDefaultColorScheme({ colorScheme: 'blue' }),
);

export default theme;
