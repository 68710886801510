/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState, memo } from 'react';
import {
  Box,
  Heading,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Button,
} from '@chakra-ui/react';
import { useLazyGetUsersQuery, useLazyGetUserCreditsQuery } from '../../api';
import TableSkeleton from '../skeletons/tableSkeleton';
import Moment from 'react-moment';
import { PiDotsThreeCircle } from 'react-icons/pi';
import 'moment-timezone';
import StatusComponent from '../ui/statusComponent';
import IdComponent from '../ui/idComponent';
import SearchInput from '../ui/searchInput';
import SelectField from '../ui/selectField';
import EmptyResult from '../ui/emptyResult';

const UserCredits = memo(({ uid }: { uid: string }) => {
  const [getCredits, { data, isLoading }] = useLazyGetUserCreditsQuery();
  useEffect(() => {
    getCredits(uid);
  }, []);
  return (
    <>
      <Td>
        <Box>
          {isLoading && <Box>Loading...</Box>}
          {data && <Box>{data.credits || 0}</Box>}
        </Box>
      </Td>
      <Td>
        <Box>
          {isLoading && <Box>Loading...</Box>}
          {data && <Box>{data.total_used || 0}</Box>}
        </Box>
      </Td>
    </>
  );
});

export default function UsersTable() {
  const [docs, setDocs] = useState<any[]>([]);
  const [nextPaginationToken, setNextToken] = useState<string | null>(null);
  const [filter, setFilter] = useState<any>({
    searchType: 'id',
    search: '',
  });
  const [getUsers, { isFetching, isError, isLoading, isSuccess }] =
    useLazyGetUsersQuery();

  useEffect(() => {
    getUsers({
      [filter.searchType]: filter.search,
    }).then(({ data }) => {
      if (data?.docs) {
        setDocs([...data.docs]);
      }
      if (data?.nextPaginationToken) {
        setNextToken(data.nextPaginationToken);
      } else {
        setNextToken(null);
      }
    });
  }, [filter]);
  return (
    <Box>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: {
            base: 'flex-start',
            sm: 'center',
          },
          flexDir: {
            base: 'column',
            sm: 'row',
          },
          py: 2,
          mb: 4,
          gap: 4,
        }}
      >
        <Heading
          fontWeight={600}
          fontSize={{
            base: 'lg',
            sm: 'xl',
            md: '2xl',
          }}
        >
          Users
        </Heading>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            gap: 4,
            flex: 1,
            maxWidth: '500px',
          }}
        >
          <SelectField
            placeholder="Status"
            options={[
              { value: 'id', label: 'Id' },
              { value: 'email', label: 'Email' },
            ]}
            minW={100}
            fullWidth
            iconLeft={<PiDotsThreeCircle size={20} />}
            value={filter.searchType}
            onChange={e => setFilter({ ...filter, searchType: e?.value })}
          />
          <SearchInput
            placeholder="Search Users..."
            value={filter.search}
            onChange={e => setFilter({ ...filter, search: e })}
          />
        </Box>
      </Box>
      <Box className="table-wraper">
        <Table>
          <Thead>
            <Tr>
              <Th minW={250} width="250px">
                Id
              </Th>
              <Th minW={250}>Email</Th>
              <Th minW={150} width={150}>
                Available Credits
              </Th>
              <Th minW={120} width={150}>
                Total Used
              </Th>
              <Th width={150}>Verified</Th>
              <Th minW={170} width={150}>
                Registered Date
              </Th>
            </Tr>
          </Thead>
          <Tbody>
            {!isLoading &&
              docs?.map((post: any) => (
                <Tr key={post.uid}>
                  <Td>
                    <IdComponent text={post.uid} />
                  </Td>
                  <Td>{post.email}</Td>
                  <UserCredits uid={post.uid} />
                  <Td>
                    <StatusComponent
                      label={
                        post.emailVerified === true
                          ? 'Verified'
                          : 'Not Verified'
                      }
                      color={post.emailVerified === true ? 'green' : 'gray'}
                    />
                  </Td>
                  <Td>
                    <Moment format="YYYY/MM/DD">
                      {post?.metadata?.creationTime}
                    </Moment>
                  </Td>
                </Tr>
              ))}
            {isLoading && (
              <Tr>
                <Td colSpan={6}>
                  <TableSkeleton rows={15} columns={6} />
                </Td>
              </Tr>
            )}
            {!isFetching && !docs?.length && (isSuccess || isError) && (
              <Tr>
                <Td colSpan={6}>
                  <EmptyResult />
                </Td>
              </Tr>
            )}
          </Tbody>
        </Table>
      </Box>
      {nextPaginationToken && (
        <Box sx={{ textAlign: 'center', mt: 4 }}>
          <Button
            colorScheme="teal"
            isLoading={isFetching}
            onClick={() => {
              getUsers({
                [filter.searchType]: filter.search,
                nextPaginationToken,
              }).then(({ data }) => {
                if (data?.docs) {
                  setDocs([...docs, ...data.docs]);
                }
                if (data?.nextPaginationToken) {
                  setNextToken(data.nextPaginationToken);
                } else {
                  setNextToken(null);
                }
              });
            }}
          >
            Load More
          </Button>
        </Box>
      )}
    </Box>
  );
}
