import React, { useEffect, useState } from 'react';
import {
  FormControl,
  FormLabel,
  FormErrorMessage,
  Box,
  Text,
} from '@chakra-ui/react';
import { IoIosCloudUpload } from 'react-icons/io';

interface ImagePickerProps {
  error?: string | undefined | boolean;
  label?: string;
  value?: string | null | undefined;
  onChange?: (value: any) => void;
}

export default function ImagePicker(props: ImagePickerProps) {
  const [preview, setPreview] = useState<string | undefined>(undefined);
  const { label, error, value, onChange } = props;

  const handleImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (file) {
      onChange && onChange(file);
      setPreview(URL.createObjectURL(file));
    }
  };

  const onDrop = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    const file = e.dataTransfer.files?.[0];
    if (file) {
      onChange && onChange(file);
      setPreview(URL.createObjectURL(file));
    }
  };

  useEffect(() => {
    if (value) {
      if (typeof value === 'string') {
        setPreview(value);
      }
    }
  }, [value]);
  return (
    <FormControl isInvalid={!!error}>
      {label && (
        <FormLabel sx={{ fontSize: 'sm', fontWeight: '600' }}>
          {label}
        </FormLabel>
      )}
      <Box
        sx={{
          position: 'relative',
          bg: 'gray.800',
          borderRadius: '10px',
          minHeight: '150px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          overflow: 'hidden',
          border: '1px dashed',
          borderColor: 'gray.700',
          cursor: 'pointer',
          input: {
            opacity: '0',
            position: 'absolute',
            top: '0',
            left: '0',
            width: '100%',
            height: '100%',
            cursor: 'pointer',
            zIndex: '10',
          },
        }}
        onDrop={onDrop}
      >
        <input type="file" accept="image/*" onChange={handleImageChange} />
        <Box
          sx={{
            position: 'absolute',
            top: '0',
            left: '0',
            width: '100%',
            height: '100%',
            display: 'flex',
            gap: '10px',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            transition: 'all 0.3s ease-in-out',
            svg: {
              opacity: '0.8',
            },
          }}
          className="overlay"
        >
          <IoIosCloudUpload size={40} />
          <Text
            sx={{
              fontSize: 'xs',
              fontWeight: '500',
            }}
          >
            Drag and drop image or{' '}
            <Text
              as="span"
              sx={{
                fontSize: 'xs',
                fontWeight: '600',
                cursor: 'pointer',
                color: 'blue.500',
              }}
            >
              Browse
            </Text>
          </Text>
        </Box>
        {preview && (
          <Box
            sx={{
              width: 'calc(100% - 14px)',
              height: 'calc(100% - 14px)',
              position: 'absolute',
              top: '7px',
              left: '7px',
              borderRadius: '6px',
              backgroundSize: 'cover',
              backgroundPosition: 'center',
              backgroundImage: `url(${preview})`,
              transition: 'all 0.3s ease-in-out',
              zIndex: '5',
            }}
            className="preview"
          ></Box>
        )}
      </Box>

      {error && (
        <FormErrorMessage
          sx={{
            color: 'red.400',
            fontWeight: '600',
            fontSize: '12px',
            mt: 1,
          }}
        >
          {error}
        </FormErrorMessage>
      )}
    </FormControl>
  );
}
