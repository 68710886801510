import React from 'react';
import { Box, Text } from '@chakra-ui/react';
import { Link } from 'react-router-dom';
import logo from '../../assets/icon.svg';

export default function Logo() {
  return (
    <Box as={Link} to="/" display="flex" alignItems="center">
      <Box
        as="img"
        src={logo}
        alt="Logo"
        mr={2}
        height="55px"
        width="55px"
        objectFit="contain"
      />
      <Text fontWeight="bold" fontSize="2xl">
        CapGen
      </Text>
    </Box>
  );
}
