import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  AlertDialogCloseButton,
  Button,
} from '@chakra-ui/react';
import React from 'react';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  name: string;
  isLoading: boolean;
  onDelete: () => void;
}

function DeleteDialog({ isOpen, onClose, name, isLoading, onDelete }: Props) {
  const cancelRef = React.useRef(null);

  return (
    <AlertDialog
      isOpen={isOpen}
      leastDestructiveRef={cancelRef}
      onClose={onClose}
      isCentered
      motionPreset="slideInBottom"
    >
      <AlertDialogOverlay>
        <AlertDialogContent
          sx={{
            bg: 'gray.800',
            borderRadius: 'xl',
          }}
        >
          <AlertDialogCloseButton />
          <AlertDialogHeader fontSize="lg" fontWeight="bold">
            Delete {name}
          </AlertDialogHeader>

          <AlertDialogBody>
            Are you sure? You can't undo this action afterwards.
          </AlertDialogBody>

          <AlertDialogFooter>
            <Button
              sx={{
                color: 'white',
                _hover: {
                  bg: 'white',
                  color: 'gray.900',
                },
              }}
              ref={cancelRef}
              variant="ghost"
              onClick={onClose}
            >
              Cancel
            </Button>
            <Button
              variant="delete"
              isLoading={isLoading}
              onClick={onDelete}
              ml={3}
            >
              Delete
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  );
}

export default DeleteDialog;
