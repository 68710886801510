import React from 'react';
import { useGetStatsQuery } from '../../api';
import { Box, Text, CircularProgress } from '@chakra-ui/react';

interface StatBoxProps {
  type: string;
  label: string;
  icon: React.ReactNode;
  suffix?: string;
}

export default function StatBox(props: StatBoxProps) {
  const { type, label, icon, suffix } = props;
  const { data, isLoading } = useGetStatsQuery(type);
  return (
    <Box
      bg="gray.800"
      borderRadius="7px"
      sx={{
        border: '1px solid #141922',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          p: '20px',
        }}
      >
        <Box
          sx={{
            height: '45px',
            width: '45px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            borderRadius: '10px',
            bg: 'gray.700',
            color: 'white',
            fontSize: 'xl',
          }}
        >
          {icon}
        </Box>
        <Box>
          {isLoading ? (
            <CircularProgress isIndeterminate color="blue.300" size={7} />
          ) : (
            <Text fontSize="2xl" fontWeight={600}>
              {data?.count || 0} {suffix}
            </Text>
          )}
        </Box>
      </Box>
      <Box
        sx={{
          bg: 'gray.900',
          p: '20px',
          borderRadius: '0 0 7px 7px',
        }}
      >
        <Text fontSize="sm" color="white">
          {label}
        </Text>
      </Box>
    </Box>
  );
}
