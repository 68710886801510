import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

const apiUrl = process.env.REACT_APP_API_URL || '';

export const Api = createApi({
  reducerPath: 'api',
  tagTypes: [],
  baseQuery: fetchBaseQuery({
    baseUrl: apiUrl,
    prepareHeaders: headers => {
      return headers;
    },
    credentials: 'include',
  }),
  endpoints: builder => ({
    login: builder.mutation({
      query: body => ({
        url: '/login-dashboard',
        method: 'POST',
        body,
      }),
    }),
    authenticate: builder.mutation<void, void>({
      query: () => ({
        url: '/authenticate',
        method: 'POST',
      }),
    }),
    createPost: builder.mutation({
      query: body => ({
        url: '/blog',
        method: 'POST',
        body,
      }),
    }),
    getPosts: builder.query({
      query: query => ({
        url: `/blog`,
        method: 'GET',
        params: query,
      }),
    }),
    getPost: builder.query({
      query: id => ({
        url: `/blog/${id}`,
        method: 'GET',
      }),
    }),
    updatePost: builder.mutation({
      query: ({ id, body }) => ({
        url: `/blog/${id}`,
        method: 'PUT',
        body,
      }),
    }),
    deletePost: builder.mutation({
      query: id => ({
        url: `/blog/${id}`,
        method: 'DELETE',
      }),
    }),
    getContacts: builder.query({
      query: query => ({
        url: `/contact`,
        method: 'GET',
        params: query,
      }),
    }),
    getContact: builder.query({
      query: id => ({
        url: `/contact/${id}`,
        method: 'GET',
      }),
    }),
    deleteContact: builder.mutation({
      query: id => ({
        url: `/contact/${id}`,
        method: 'DELETE',
      }),
    }),
    updateContact: builder.mutation({
      query: ({ id, body }) => ({
        url: `/contact/${id}`,
        method: 'PUT',
        body,
      }),
    }),
    getUsers: builder.query({
      query: query => ({
        url: `/users`,
        method: 'GET',
        params: query,
      }),
    }),
    getUserCredits: builder.query({
      query: id => ({
        url: `/users/${id}/credits`,
        method: 'GET',
      }),
    }),
    getTransactions: builder.query({
      query: query => ({
        url: `/transactions`,
        method: 'GET',
        params: query,
      }),
    }),
    getStats: builder.query({
      query: type => ({
        url: `/stats/${type}`,
        method: 'GET',
      }),
    }),
  }),
});

export const {
  useLoginMutation,
  useAuthenticateMutation,
  useCreatePostMutation,
  useLazyGetPostQuery,
  useGetPostsQuery,
  useUpdatePostMutation,
  useDeletePostMutation,
  useGetContactsQuery,
  useLazyGetContactQuery,
  useDeleteContactMutation,
  useUpdateContactMutation,
  useLazyGetUsersQuery,
  useLazyGetUserCreditsQuery,
  useGetTransactionsQuery,
  useGetStatsQuery,
} = Api;
