import { createSlice } from '@reduxjs/toolkit';

const appSlice = createSlice({
  name: 'app',
  initialState: {
    user: null,
    showSidebar: false,
  },
  reducers: {
    loginReducer: (state, action) => {
      state.user = action.payload;
    },
    logoutReducer: state => {
      state.user = null;
    },
    setShowSidebar: (state, action) => {
      state.showSidebar = action.payload;
    },
  },
});

export const { loginReducer, logoutReducer, setShowSidebar } = appSlice.actions;
export default appSlice.reducer;
