/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import {
  Box,
  Heading,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  useToast,
} from '@chakra-ui/react';
import { useGetContactsQuery, useDeleteContactMutation } from '../../api';
import TableSkeleton from '../skeletons/tableSkeleton';
import Moment from 'react-moment';
import 'moment-timezone';
import Pagination from '../ui/pagination';
import DeleteDialog from '../ui/deleteDialog';
import StatusComponent from '../ui/statusComponent';
import IdComponent from '../ui/idComponent';
import ActionButton from '../ui/actionButton';
import EmptyResult from '../ui/emptyResult';
import ViewContactModal from '../modals/viewContactModal';
import { IoMdArrowUp, IoMdArrowDown } from 'react-icons/io';

const SortableTh = ({ width, label, sortKey, filter, setFilter }: any) => {
  const isSorted = filter[sortKey] !== undefined;
  const isDesc = filter[sortKey] === 'desc';
  const Icon = isDesc ? IoMdArrowDown : IoMdArrowUp;
  const handleClick = () => {
    if (!isSorted) {
      setFilter({
        page: 1,
        limit: 15,
        [sortKey]: 'desc',
      });
    } else if (isDesc) {
      setFilter({
        page: 1,
        limit: 15,
        [sortKey]: 'asc',
      });
    } else {
      setFilter({
        page: 1,
        limit: 15,
      });
    }
  };
  return (
    <Th width={width || 100} onClick={handleClick} sx={{ cursor: 'pointer' }}>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        {label} {isSorted && <Icon />}
      </Box>
    </Th>
  );
};

export default function ContactsTable() {
  const [viewId, setViewId] = useState<string | null>(null);
  const toast = useToast();
  const [deleteId, setDeleteId] = useState<string | null>(null);
  const [filter, setFilter] = useState<any>({
    page: 1,
    limit: 15,
  });
  const { data, isLoading, isFetching, refetch } = useGetContactsQuery(filter);
  const [deleteContact, { isLoading: isDeleting, isError, isSuccess }] =
    useDeleteContactMutation();

  useEffect(() => {
    refetch();
  }, [filter, refetch]);

  useEffect(() => {
    if (isSuccess) {
      toast({
        title: 'Contact deleted successfully',
        status: 'success',
        duration: 3000,
        isClosable: true,
      });
      setDeleteId(null);
      refetch();
    }

    if (isError) {
      toast({
        title: 'Something went wrong',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
      setDeleteId(null);
    }
  }, [isSuccess, toast, isError]);
  return (
    <Box>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          py: 2,
          mb: 4,
          gap: 4,
        }}
      >
        <Heading
          fontWeight={600}
          fontSize={{
            base: 'lg',
            sm: 'xl',
            md: '2xl',
          }}
        >
          Contacts
        </Heading>
      </Box>
      <Box className="table-wraper">
        <Table>
          <Thead>
            <Tr>
              <Th width="250px">Id</Th>
              <SortableTh
                width={250}
                label="Name"
                sortKey="nameSort"
                filter={filter}
                setFilter={setFilter}
              />
              <SortableTh
                width={250}
                label="Email"
                sortKey="emailSort"
                filter={filter}
                setFilter={setFilter}
              />
              <SortableTh
                width={150}
                label="Status"
                sortKey="statusSort"
                filter={filter}
                setFilter={setFilter}
              />
              <SortableTh
                width={150}
                label="Created At"
                sortKey="createdAtSort"
                filter={filter}
                setFilter={setFilter}
              />
              <Th width="150px">Actions</Th>
            </Tr>
          </Thead>
          <Tbody>
            {!isFetching &&
              data?.docs?.map((post: any) => (
                <Tr key={post.id}>
                  <Td>
                    <IdComponent text={post.id} />
                  </Td>
                  <Td>{post.name}</Td>
                  <Td>{post.email}</Td>
                  <Td>
                    <StatusComponent
                      label={post.status}
                      color={post.status === 'completed' ? 'green' : 'yellow'}
                    />
                  </Td>
                  <Td>
                    <Moment format="YYYY/MM/DD">{post.createdAt}</Moment>
                  </Td>
                  <Td>
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: 2,
                      }}
                    >
                      <ActionButton
                        type="view"
                        onClick={() => setViewId(post.id)}
                      />
                      <ActionButton
                        type="delete"
                        onClick={() => setDeleteId(post.id)}
                      />
                    </Box>
                  </Td>
                </Tr>
              ))}
            {(isLoading || isFetching) && (
              <Tr>
                <Td colSpan={6}>
                  <TableSkeleton rows={15} columns={6} />
                </Td>
              </Tr>
            )}
            {!isLoading && !data?.docs?.length && (
              <Tr>
                <Td colSpan={6}>
                  <EmptyResult />
                </Td>
              </Tr>
            )}
          </Tbody>
        </Table>
      </Box>
      <Pagination
        totalPages={data?.total ? Math.ceil(data.total / data.limit) : 1}
        onPageChange={(page: any) => setFilter({ ...filter, page: page })}
        currentPage={filter.page}
      />
      <DeleteDialog
        isOpen={!!deleteId}
        onClose={() => setDeleteId(null)}
        name="Contact"
        isLoading={isDeleting}
        onDelete={() => {
          deleteContact(deleteId || '');
        }}
      />
      <ViewContactModal
        id={viewId}
        onClose={() => setViewId(null)}
        onRefresh={() => refetch()}
      />
    </Box>
  );
}
