import React from 'react';
import { Box, Container } from '@chakra-ui/react';
import UsersTable from '../components/tables/usersTable';

export default function Blogs() {
  return (
    <Box sx={{ py: '21px' }}>
      <Container maxW="container.xl">
        <UsersTable />
      </Container>
    </Box>
  );
}
