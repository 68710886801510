import React from 'react';
import { Box, Container, Heading } from '@chakra-ui/react';
import StatBox from '../components/ui/statBox';
import { BsCreditCard2FrontFill } from 'react-icons/bs';
import { FaMoneyBillWave } from 'react-icons/fa';
import { GrTransaction } from 'react-icons/gr';
import { HiMiniUserGroup } from 'react-icons/hi2';
import DashboardTables from '../components/tables/dashboardTransectionsTables';

export default function Dashboard() {
  return (
    <Box sx={{ py: '30px' }}>
      <Container maxW="container.xl">
        <Heading fontWeight={600} fontSize="2xl">
          Overview
        </Heading>
        <Box
          sx={{
            display: 'grid',
            gridTemplateColumns: {
              base: 'repeat(1, 1fr)',
              sm: 'repeat(2, 1fr)',
              md: 'repeat(3, 1fr)',
            },
            gridGap: '20px',
            mt: '20px',
          }}
        >
          <StatBox
            type="today_used_credits"
            label="Today credits used"
            icon={<BsCreditCard2FrontFill />}
          />
          <StatBox
            type="today_transactions_amount"
            label="Total Earnings"
            suffix="$"
            icon={<FaMoneyBillWave />}
          />
          <StatBox
            type="today_signups"
            label="Today registered users"
            icon={<HiMiniUserGroup />}
          />
          <StatBox
            type="users"
            label="Total users"
            icon={<HiMiniUserGroup />}
          />
          <StatBox
            type="used_credits"
            label="Total credits used"
            icon={<BsCreditCard2FrontFill />}
          />
          <StatBox
            type="transactions"
            label="Total transactions"
            icon={<GrTransaction />}
          />
        </Box>
        <DashboardTables />
      </Container>
    </Box>
  );
}
