import React from 'react';
import { Box } from '@chakra-ui/react';
import { menuLinks, bottomLinks } from './menuLinks';
import { useLocation, Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { setShowSidebar } from '../../redux/slices/app.slice';

const MenuItem = ({ url, icon, label, color }: any) => {
  const dispatch = useDispatch();
  const location = useLocation();
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        gap: 4,
        p: 2,
        fontSize: '13px',
        position: 'relative',
        _hover: {
          svg: {
            color: color || 'blue.500',
          },
        },
        fontWeight: '600',
        color: color || 'white',
        svg: {
          transition: 'all 0.2s',
          fontSize: 'xl',
          color: color
            ? color
            : location.pathname === url
              ? 'blue.500'
              : 'white',
        },
        _after: {
          content: '""',
          position: 'absolute',
          top: '50%',
          transform: 'translateY(-50%)',
          left: '-24px',
          width: '3px',
          height: '20px',
          borderRadius: '0 3px 3px 0',
          backgroundColor:
            location.pathname === url ? 'blue.500' : 'transparent',
        },
      }}
      as={Link}
      to={url}
      key={label}
      onClick={() => dispatch(setShowSidebar(false))}
    >
      <Box as={icon} />
      {label}
    </Box>
  );
};

export default function Menu() {
  return (
    <Box
      sx={{
        flex: 1,
        overflowY: 'auto',
        px: 6,
        py: 2,
        display: 'flex',
        flexDirection: 'column',
        gap: 2,
        '&::-webkit-scrollbar': {
          width: '4px',
        },
        '&::-webkit-scrollbar-track': {
          width: '6px',
        },
        '&::-webkit-scrollbar-thumb': {
          background: 'gray.700',
          borderRadius: '24px',
        },
        '&::-webkit-scrollbar-thumb:hover': {
          background: 'gray.600',
        },
      }}
    >
      {menuLinks.map((link, i) => (
        <MenuItem {...link} key={i} />
      ))}
    </Box>
  );
}

export const BottomMenu = () => {
  return (
    <Box
      sx={{
        px: 6,
        py: 2,
        pb: 6,
        display: 'flex',
        flexDirection: 'column',
        gap: 2,
      }}
    >
      <Box
        sx={{
          fontSize: 'sm',
          fontWeight: '700',
          color: 'gray.300',
          pl: 2,
        }}
      >
        Settings
      </Box>
      {bottomLinks.map((link, i) => (
        <MenuItem {...link} key={i} />
      ))}
    </Box>
  );
};
