import React from 'react';
import { Box } from '@chakra-ui/react';
import Logo from './logo';
import Menu, { BottomMenu } from './menu';
import { useSelector, useDispatch } from 'react-redux';
import { setShowSidebar } from '../../redux/slices/app.slice';

export default function Sidebar() {
  const dispatch = useDispatch();
  const showSidebar = useSelector((state: any) => state.app.showSidebar);
  return (
    <>
      <Box
        sx={{
          position: 'fixed',
          top: 0,
          left: 0,
          bottom: 0,
          width: '280px',
          borderRight: '1px solid',
          borderColor: 'gray.700',
          backgroundColor: 'gray.900',
          display: 'flex',
          zIndex: 100,
          flexDirection: 'column',
          transition: 'transform 0.3s ease',
          '@media (max-width: 1070px)': {
            transform: 'translateX(-100%)',
          },
          '@media (max-width: 1070px) and (min-width: 0px)': {
            transform: showSidebar ? 'translateX(0)' : 'translateX(-100%)',
          },
        }}
      >
        <Box
          sx={{
            px: 6,
            py: 6,
            '& img': {
              width: '40px',
              height: '40px',
            },
            '& p': {
              fontWeight: 'bold',
              fontSize: '2xl',
            },
          }}
        >
          <Logo />
        </Box>
        <Menu />
        <BottomMenu />
      </Box>
      <Box
        sx={{
          position: 'fixed',
          top: 0,
          left: 0,
          bottom: 0,
          width: '100%',
          backgroundColor: 'rgba(0, 0, 0, 0.5)',
          backdropFilter: 'blur(2px)',
          zIndex: 99,
          display: 'none',
          '@media (max-width: 1070px)': {
            display: showSidebar ? 'block' : 'none',
          },
        }}
        onClick={() => {
          dispatch(setShowSidebar(false));
        }}
      />
    </>
  );
}
